import logo from './NUTRINA_LOGO.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {/* Edit <code>src/App.js</code> and save to reload. */}
          Stay Tuned! Website coming soon :)
          <br></br>
          But for now, shop on <a href="https://delli.market/products/nutrinanas-special-granola?srsltid=AfmBOorT2Xj4IWGiK9IT7sG_UsL4JRnW2bHQ-DzvnvsATtaQmOhrfS3m">DELLI</a>
        </p>
      </header>
    </div>
  );
}

export default App;
